import React from 'react'
import styled, { CSSProperties } from 'styled-components'

import Button from 'components/shared/button'
import Icon from 'components/shared/icon'
import { Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import arrowIcon from 'assets/icons/arrow-control.svg'
import arrowIconBlack from 'assets/icons/arrow-dropdown.svg'

import type { ActiveRequired } from 'types/shared/active'

type PaginationProps = {
  onChange: (index: number, scrollOnChange?: boolean) => void

  currentPage: number
  maxPage: number
  position?: CSSProperties['justifyContent']
}

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 80px;

  ${media.lg.min} {
    padding-bottom: 100px;
    justify-content: flex-end;
  }
`

const PaginationButton = styled(Button).attrs({
  fontSize: 16,
  outline: ({ active }: ActiveRequired) => !active,
})<ActiveRequired>`
  width: 47px;
  height: 47px;

  border-radius: 5px;
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.midnightgreen};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.midnightgreen : theme.colors.white};

  padding: 10px;
  &:not(:first-child) {
    margin-left: 15px;
  }
`

const PaginationMobileButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 0;
  border: 2px solid ${({ theme }) => theme.colors.midnightgreen};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-of-type {
    img {
      transform: rotate(180deg);
    }
  }
`

const StyledMobilePageInfoText = styled(Text)`
  padding: 0 20px;
  margin: 0;
`

const NextPageButton = styled.button`
  margin-left: 25px;
  background: none;
  border: none;
  align-self: stretch;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-left: 15px;
  }
`

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  maxPage,
  onChange,
}) => {
  const { sm, lg } = useBreakpoint()
  const PAGES_AROUND = sm ? 2 : 1
  const pagesDOM: JSX.Element[] = []

  const renderButton = (index: number | null, text: string) => {
    const active = index === currentPage
    return (
      <PaginationButton
        key={index}
        active={active}
        onClick={() => {
          if (index !== null) onChange(index)
        }}
      >
        {text}
      </PaginationButton>
    )
  }

  for (
    let i = Math.max(0, currentPage - PAGES_AROUND);
    i <= Math.min(maxPage, currentPage + PAGES_AROUND);
    i += 1
  ) {
    pagesDOM.push(renderButton(i, `${i + 1}`))
  }

  if (maxPage > 0) {
    return lg ? (
      <PaginationWrapper>
        {currentPage - PAGES_AROUND > 0 && (
          <>
            {renderButton(0, '1')}
            {currentPage - PAGES_AROUND - 1 !== 0 && renderButton(null, '...')}
          </>
        )}
        {pagesDOM}

        {currentPage + PAGES_AROUND < maxPage && (
          <>
            {currentPage + PAGES_AROUND + 1 !== maxPage &&
              renderButton(null, '...')}
            {renderButton(maxPage, `${maxPage + 1}`)}
          </>
        )}
        {currentPage !== maxPage && (
          <NextPageButton onClick={() => onChange(currentPage + 1)}>
            <Text margin="0" weight={600}>
              Następna
            </Text>
            <Icon src={arrowIconBlack} />
          </NextPageButton>
        )}
      </PaginationWrapper>
    ) : (
      <PaginationWrapper>
        <PaginationMobileButton
          onClick={() => {
            if (currentPage !== 0) onChange(currentPage - 1)
          }}
        >
          <Icon src={arrowIcon} />
        </PaginationMobileButton>
        <StyledMobilePageInfoText>{`Strona ${currentPage + 1} z ${
          maxPage + 1
        }`}</StyledMobilePageInfoText>
        <PaginationMobileButton
          onClick={() => {
            if (currentPage !== maxPage) onChange(currentPage + 1)
          }}
        >
          <Icon src={arrowIcon} />
        </PaginationMobileButton>
      </PaginationWrapper>
    )
  }

  return null
}

export default Pagination
