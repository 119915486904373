import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

import Breadcrumbs from 'components/shared/breadcrumbs'

import Header from 'components/header'
import PostsFilters from 'components/pages/blog/posts-filters'

import { BlogPageQuery } from 'types/graphql'

const Blog: React.FC<PageProps<BlogPageQuery>> = ({ data }) => {
  const PAGE = data?.wpPage?.blogPage
  const PAGE_SEO = data?.wpPage?.seo!

  const BLOG_POSTS =
    data?.allWpPost?.nodes?.map(
      ({ title, slug, date, excerpt, featuredImage, categories }) => ({
        title: title!,
        slug: slug!,
        date: new Date(date).toLocaleDateString()!,
        excerpt: excerpt!,
        image: {
          src: featuredImage?.node?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: featuredImage?.node?.altText!,
        },
        category: categories?.nodes?.[0]?.name!,
      })
    ) || []

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <Header
        image={{
          src: PAGE?.blogPageS1Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.blogPageS1Img?.altText!,
        }}
        title={PAGE?.blogPageS1Title!}
        subtitle={PAGE?.blogPageS1Paragraph!}
        page="blog"
      />
      <Breadcrumbs crumbs={[{ name: 'Inspiracje' }]} />
      <PostsFilters allPosts={BLOG_POSTS!} />
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query BlogPage {
    wpPage(slug: { regex: "/blog/" }) {
      seo {
        ...WpSEO
      }
      blogPage {
        blogPageS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        blogPageS1Title
        blogPageS1Paragraph
      }
    }
    allWpPost {
      nodes {
        title
        slug
        date
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
          }
        }
        categories {
          nodes {
            name
          }
        }
      }
    }
  }
`
