import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import Button from 'components/shared/button'
import LazyImage from 'components/shared/lazy-image'

import Pagination from 'components/pagination'

import useBreakpoint from 'hooks/useBreakpoint'

import { scrollTo } from 'utils/scrollToSection'

import media from 'styles/media'

import close from 'assets/icons/close-small.svg'

import { PostThumbnail } from 'types/post'

const StyledContainer = styled(Container)`
  padding: 0;

  ${media.lg.min} {
    padding: 0 30px 0 30px;
  }
`

const StyledFilters = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  padding: 0 36px;

  ${media.lg.min} {
    grid-template-columns: repeat(auto-fill, 216px);
    gap: 20px;
    padding: 0;
  }
`

const StyledFilterItem = styled.div<{ active?: boolean }>`
  height: 50px;
  font-size: 14px;

  position: relative;
  border-radius: 70px;
  border: 2px solid ${({ theme }) => theme.colors.pewterblue};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.pewterblue};
  white-space: nowrap;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s ease-out;

  img {
    display: none;

    ${media.lg.min} {
      display: block;
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: 0.3s ease-out;
    }
  }

  ${media.lg.min} {
    width: 216px;
    height: 60px;
    font-size: 18px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.pewterblue};
      color: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.pewterblue};
      color: ${({ theme }) => theme.colors.white};

      &:hover {
        img {
          opacity: 1;
        }
      }
    `}
`

const StyledBlogPostsWrapper = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${media.lg.min} {
    padding: 60px 0 50px 0;
  }
`

const StyledPostCardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;

    button {
      visibility: hidden;
      opacity: 0;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }

    &:hover {
      button {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  a {
    display: flex;
    flex-direction: column;
  }

  &:nth-child(1n) {
    background-color: ${({ theme }) => theme.colors.pewterblue};
  }

  &:nth-child(2n) {
    background-color: ${({ theme }) => theme.colors.jet};
  }

  &:nth-child(3n) {
    background-color: ${({ theme }) => theme.colors.midnightgreen};
  }
`

const StyledPostCardImageWrapper = styled.div`
  height: 200px;

  ${media.lg.min} {
    height: 300px;
    width: 30%;
  }
`

const StyledPostCardContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 25px 36px 30px 36px;

  ${media.lg.min} {
    padding: 40px 40px 25px 40px;
    width: 70%;
  }

  &:before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    height: 1px;
    left: 0;
    top: 35px;
    width: 21px;

    ${media.lg.min} {
      top: 50px;
      width: 26px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    height: 1px;
    right: 0;
    top: 35px;
    width: 120px;

    ${media.lg.min} {
      top: 50px;
      width: 125px;
    }
  }
`

const StyledTitle = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  line-height: 22px;

  padding-right: 89px;

  ${media.lg.min} {
    font-size: 22px;
    line-height: 30px;
  }
`

const StyledExcerpt = styled.div`
  color: ${({ theme }) => theme.colors.white};
  margin-top: 10px;
  font-size: 15px;

  ${media.lg.min} {
    font-size: 17px;
    line-height: 1.4;
  }
`

const StyledDateAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledDate = styled.p`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.white};
`

const StyledReadMoreButton = styled(Button)`
  margin-top: 30px;
  background: transparent;
  border-color: white;
  font-size: 16px;

  ${media.lg.min} {
    width: 50%;
    font-size: 18px;
  }
`

type Props = {
  allPosts: PostThumbnail[]
}

const PostsFilters: React.FC<Props> = ({ allPosts }) => {
  const [activeRooms, setActiveRooms] = useState(['wszystkie'])

  const [activePosts, setActivePosts] = useState(allPosts)

  const perPage = { desktop: 5, mobile: 3 }

  const [currentPage, setCurrentPage] = useState(0)
  const [postsPerPage, setPostsPerPage] = useState<number>(perPage.desktop)
  const { lg } = useBreakpoint()

  const filters = [
    'wszystkie',
    ...Array.from(new Set(allPosts?.map(({ category }) => category))),
  ]

  useEffect(() => {
    if (activeRooms.includes('wszystkie')) {
      setActivePosts(allPosts)
    } else {
      setActivePosts(
        allPosts?.filter((post) => activeRooms.includes(post!.category!))
      )
    }
  }, [activeRooms])

  const toggleOption = (value: string) => {
    setCurrentPage(0)

    if (value === 'wszystkie') {
      setActiveRooms(['wszystkie'])
      return
    }
    if (activeRooms.includes(value)) {
      const newRooms = activeRooms.filter((el) => el !== value)
      setActiveRooms(newRooms.length === 0 ? ['wszystkie'] : newRooms)
      return
    }
    setActiveRooms([...activeRooms.filter((el) => el !== 'wszystkie'), value])
  }

  const postsRef = useRef()

  const changePage = (index: number) => {
    setCurrentPage(index)
    sessionStorage.setItem('current-page', index)
    scrollTo(postsRef.current.offsetTop - 50)
  }

  const pagePosts = [...activePosts].slice(
    currentPage * postsPerPage,
    (currentPage + 1) * postsPerPage
  )

  const currentView = lg ? 'desktop' : 'mobile'

  useEffect(() => {
    setCurrentPage(0)
  }, [allPosts])

  useEffect(() => {
    setPostsPerPage(perPage[currentView])
    setCurrentPage(0)
  }, [lg, perPage.desktop, perPage.mobile])

  useEffect(() => {
    const page = Number(sessionStorage.getItem('current-page'))
    if (page && !Number.isNaN(page)) {
      setCurrentPage(page)
    }
  }, [])

  return (
    <StyledContainer>
      <StyledFilters>
        {filters.map((el, index) => (
          <StyledFilterItem
            key={`${el}-${index}`}
            onClick={() => toggleOption(el)}
            active={activeRooms.includes(el)}
          >
            {el}
            <img src={close} alt="" />
          </StyledFilterItem>
        ))}
      </StyledFilters>

      <StyledBlogPostsWrapper ref={postsRef}>
        {pagePosts?.map(({ title, slug, date, excerpt, image }, index) => (
          <StyledPostCardWrapper
            as={Link}
            to={`/blog/${slug}/`}
            key={`${index}-${title}`}
          >
            <StyledPostCardImageWrapper>
              <LazyImage src={image?.src} alt={image.alt} fill />
            </StyledPostCardImageWrapper>
            <StyledPostCardContentWrapper>
              <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
              <StyledExcerpt dangerouslySetInnerHTML={{ __html: excerpt }} />
              <StyledDateAndButtonWrapper>
                <StyledDate>{date}</StyledDate>
                <StyledReadMoreButton>Czytaj więcej</StyledReadMoreButton>
              </StyledDateAndButtonWrapper>
            </StyledPostCardContentWrapper>
          </StyledPostCardWrapper>
        ))}
      </StyledBlogPostsWrapper>
      {postsPerPage < activePosts.length && (
        <Pagination
          onChange={changePage}
          currentPage={currentPage}
          maxPage={Math.ceil(activePosts.length / postsPerPage) - 1}
        />
      )}
    </StyledContainer>
  )
}

export default PostsFilters
